import React from 'react';
import { connect } from 'react-redux';
import {render} from 'react-dom'
import ReactHintFactory from 'react-hint'
import 'react-hint/css/index.css'
import {GraphViewComponent} from '.';
import {gameActions, modalActions} from '../_actions';
import { soundPlayers } from '../_helpers';
import { HeaderBar} from '../_components';
import $ from "jquery";
import { LoginBox } from './LoginBox';
var app_config = require('../app_config.json')[process.env.NODE_ENV];
var classNames = require('classnames');
const ReactHint = ReactHintFactory(React);
const RESULTS = ['ou-over', 'ou-under', 'pou-over', 'pou-under', 'oe-odd', 'oe-even', 'poe-odd', 'poe-even'];
var picksterRows = [];
class GamePage extends React.Component {

    state = {
        isBeingAnimation: false,
        isPlayingSound : false,
        rightMenuFlag : 0,
        leftMenuFlag : 0,
    };

    constructor(){
        super();
        this.startClickTimer();
    }

    componentDidMount() {
    }

    componentDidUpdate(){
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.sockets.isEnded && !this.state.isBeingAnimation){
            this.startResultAnimation();
        }
    }

    handleClickRightMenu(flag){
        this.setState({rightMenuFlag: flag});
    }

    
    handleClickLeftMenu(flag){
        this.setState({leftMenuFlag: flag});
    }

    startResultAnimation(){
        let self = this;
        const { sockets } = this.props;
        let { currentResult, currentWinResult, isEnded } = sockets;
        let {round, result} = currentWinResult;
        var $game_board = $("#game_board");
        var $waiting_board = $("#waiting_board");
        var $waiting_score_board = $("#waiting_score_board");
        var $score_board = $("#score_board");
        var $result_board = $("#result_board");
        var $dist_graph = $("#dist_graph");
        this.setState({isBeingAnimation: true, isBeingAnimationForPickster: true, isPlayingSound : false});
        var cast_bx_idx = 0;
        var ani_move = setInterval(function () {
            cast_bx_idx++;
            if (cast_bx_idx > 7) cast_bx_idx = 0;
            $game_board.find('.bg_animation span').removeClass().addClass('ani' + cast_bx_idx);
            $waiting_score_board.hide();
        }, 100);
        $waiting_board.fadeOut(500);
        $waiting_score_board.fadeOut(500);
        var show_scoreboard = function(){
            $waiting_score_board.hide();
            $score_board.fadeIn(1000);
            $score_board.find('.curr_round .pick_num').html('<strong>' + round + '회차' +'</strong>');
            $score_board.find('.curr_round span')[1].innerText = currentWinResult['pick_num'];
        };
        setTimeout(function () {
            show_scoreboard();
            setTimeout(function () { cast_lots(); }, 2000);
        }, 3000);

        var ball_idx = 0;
        var ball_type = 'n';
        var cast_lots = function () {
            $waiting_score_board.hide();
            soundPlayers.ballSoundPlayer.play();
            let resball = result[ball_idx];
            if(parseInt(resball) === 10)
                resball = '0';
            $score_board.find('.result_ball').append("<em class='" + ball_type + resball + "'></em>");
            if (ball_idx === 4) {
                $game_board.find('.bg_animation').addClass('powerball');
                ball_type = 'p';
            }
            if (ball_idx === 5) {
                clearInterval(ani_move);
                animate_callback();
            }
            if (ball_idx < 5) {
                setTimeout(function () { cast_lots(); }, 1500);
            }
            ball_idx++;
        };

        var gameReset = function () {
            $waiting_score_board.hide();
            $game_board.find('.bg_animation').removeClass('powerball');
            $game_board.find('.bg_animation > span').removeClass().addClass('ani0');
            $score_board.find('.result_ball').empty();
            $score_board.find('.result_info .normalball').empty();
            $score_board.find('.result_info .powerball').empty();
            $score_board.fadeOut(500);
            //$result_board.fadeOut(0);
            $waiting_board.fadeIn(500);
            soundPlayers.readySoundPlayer.stop();
            self.setState({isBeingAnimation: false, isPlayingSound: false});
            setTimeout(function(){
                self.setState({isBeingAnimationForPickster : false});
            }, 5000);
        };

        var animate_callback = function (status = true) {
            if(status) {
                let normalSum = 0;
                for(let i =0; i < 5; i++){
                    normalSum += parseInt(currentWinResult.result[i]);
                }

                $score_board.find('.result_info .normalball').html(
                    '<span class="name">'+ normalSum +'</span>' +
                    '<ul>' +
                        '<li class="icon_'+ currentWinResult['oe'] + '"></li>' +
                        '<li class="icon_'+ currentWinResult['ou'] + '"></li>' +
                    '</ul>');
                $score_board.find('.result_info .powerball').html(
                    '<span class="name">'+ currentWinResult.result[5] +'</span>' +
                    '<ul>' +
                        '<li class="icon_'+ currentWinResult['poe'] +'"></li>' +
                        '<li class="icon_'+ currentWinResult['pou'] +'"></li>' +
                    '</ul>');
                $game_board.find('.bg_animation > span').removeClass().addClass('ani0');
                //$result_board.fadeIn(2000);
            }

            setTimeout(function () {
                //$waiting_board.removeClass("msg").find(".tx").html('<span id="countdown_clock" class="countdown_clock">-분 --초 후</span>  <strong class="next_turn">' + (currentResult.round) + '</strong>회차 추첨 시작');
                //$score_board.find('.curr_round .pick_num strong')[0].innerText = currentResult.round;
                //$score_board.find('.curr_round .pick_num2 span')[0].innerText = ' (' + round + ')';
                //$waiting_board.find('.next_turn')[0].innerText = currentResult.round;
                gameReset();
                //minigame_banner.getNewBanner();
            }, status ? 5000 : 0);
        };
    }

    startClickTimer(){
        var self = this;
        let {isBeingAnimation} = this.state
        let timerId = setInterval(function(){
            const {dispatch, sockets} = self.props;
            if(sockets.currentResult.secondsLeft === undefined) return;
            try{
                dispatch(gameActions.downLeftTime());
                if(sockets.currentResult.secondsLeft === 6 && !self.state.isPlayingSound){
                    soundPlayers.readySoundPlayer.play();
                    self.setState({isPlayingSound: true});
                }
                if(sockets.currentResult.secondsLeft < 0){
                    $("#waiting_board").hide();
                    if(isBeingAnimation === false && sockets.currentResult.secondsLeft < -2)
                        $("#waiting_score_board").show();
                }
                if(sockets.currentResult.secondsLeft < -25){
                    alert("회차정보 오류로 인하여 결과값을 가져 올 수 없습니다.");
                    clearInterval(timerId);
                    try {
                        window.parent.location.reload();
                    } catch (e) {
                        window.location.reload();
                    }
                }
            }catch (e) {

            }
        }, 1000);
    }

    getDateTimeStr(){
        const { modal, sockets } = this.props;
        let { hourDiff, clockDiff } = sockets;
        if(clockDiff === undefined) clockDiff = 0;
        let nowTime = new Date();
        let strDate = '';
        try{
            nowTime = new Date(nowTime.getTime() + hourDiff);
            nowTime.setSeconds(nowTime.getSeconds() + clockDiff);
            //2021.01.06 02:18:31
            strDate = nowTime.toISOString().substring(0, 19);
            strDate = strDate.replace('T', ' ');
        }catch (e) {
            nowTime = new Date();
            strDate = nowTime.toISOString().substring(0, 19);
            strDate = strDate.replace('T', ' ');
        }
        return strDate;
    }

    getLeftTimeStr(seconds){
        if(seconds < 0) seconds= 0;
        seconds = seconds/1;
        let mins = Math.floor(seconds/60);
        if(isNaN(mins)) mins = "-";
        let secs = Math.floor(seconds - mins*60);
        if(isNaN(secs)) secs = "-";
        else if(secs<10) secs = "0"+secs;
        return mins + " 분 " + secs + " 초";
    }

    getLeftTimeBarLength(seconds){
        //return {};
        if(seconds < 0) seconds= 0;
        seconds = seconds/1;
        return {"left": (0-(300-seconds) * 250/300)+"px"};
    }
    renderNotice(){
        return (
            <div className="notice-win">
                <p>
                    &nbsp;게임은 러시아에서 서비스를 제공<br/>
                    하는 게임입니다.
                    <span className="time">*러시아 모스크(GMT+3)는 00:00<br/>부터 24:00까지 288회 운영됩니다.</span>

                    러시아 현지 상황으로 인해 게임이<br/>
                    중단될수 있습니다.</p>
            </div>
        );
    }
    render() {
        const { sockets } = this.props;
        let { currentResult, recentResult } = sockets;
        let lastItem = recentResult[recentResult.length - 1];
        if(lastItem == null) lastItem ={pick_num2 : 0};
        let {isBeingAnimation, isBeingAnimationForPickster,  leftMenuFlag, rightMenuFlag, soundOn} = this.state;
        return (
            <div className="po_wrap_new">
                <HeaderBar/>
                <LoginBox/>
                <div className={classNames("po_box_wrap_left_header", {"left-active" : leftMenuFlag===0, "right-active" : leftMenuFlag===1})}>
                    <div className="right" onClick={this.handleClickLeftMenu.bind(this, 0)}></div>
                    <div className="right" onClick={this.handleClickLeftMenu.bind(this, 1)}></div>
                </div>
                {
                    leftMenuFlag === 0
                    &&
                    <span className="ai_title">회차 {isBeingAnimationForPickster?lastItem.pick_num2 : currentResult.round}회 {isBeingAnimationForPickster?"결과처리중": "픽스터예측"}</span>
                }
                <div className="po_box_wrap_left_new" id="po_box_wrap_left_new">
                    {
                        leftMenuFlag === 0
                        &&
                        this.renderPlayerList()
                    }
                    {
                        leftMenuFlag === 1
                        &&
                        this.renderNotice()
                    }
                </div>
             
                <div className={classNames("po_box_wrap_right_header", {"left-active" : rightMenuFlag===0, "right-active" : rightMenuFlag===1})}>
                    <div className="right" onClick={this.handleClickRightMenu.bind(this, 0)}></div>
                    <div className="right" onClick={this.handleClickRightMenu.bind(this, 1)}></div>
                </div>
                <div className="po_box_wrap_right_new" style={{display: rightMenuFlag===0?"block": "none"}}>
                    {this.renderRecentResult()}
                </div>
                <div className="po_box_wrap_right_new"  style={{display: rightMenuFlag===1?"block": "none"}}>
                    {this.renderRecentResultNumbers()}
                </div>

                <div className="po_center_wrap_new">
                    <p id="clock" className="po_center_data" style={{"color": "#ffffff"}}>{this.getDateTimeStr()}</p>
                    <div id="game_board" className="game_board">
                        <div className="cast_lots">
                            {/*<!--span class="bg_board"></span-->*/}
                            <div className="bg_animation"><span className="ani0"></span></div>
                        </div>
                    </div>
                    <div id="waiting_board" className="waiting_board" style={{"display": isBeingAnimation?"none": "block"}}>
                        <div className="tx">
                            <div className="date_round">
                                <span id="date_round"> {currentResult.round}</span>회차
                            </div>
                            <div className="countdown_clock">
                                <span id="countdown_clock">추첨 {this.getLeftTimeStr(currentResult.secondsLeft)}전</span>
                            </div>
                        </div>
                        <div className="inner">
                            <div id="timer_gauge" className="gauge" style={this.getLeftTimeBarLength(currentResult.secondsLeft)}></div>
                        </div>
                    </div>
                    <div id="waiting_score_board" className="waiting_score_board"></div>
                    <div id="score_board" className="score_board __web-inspector-hide-shortcut__" style={{"display":"none"}}>
                        <div className="curr_round">
                            <span className="pick_num"><strong>-회차</strong></span>
                            <span className="pick_num2">-</span>
                        </div>
                        <div className="result_info">
                            <div className="normalball">
                            </div>
                            <div className="powerball">
                            </div>
                        </div>
                        <span className="result_ball">
                        </span>

                    </div>
                    <dl id="result_board" className="result_board" style={{"display": "none"}}>
                        <dt>회차</dt>
                        <dd className="round"></dd>
                        <dt>파워볼</dt>
                        <dd className="powerball"></dd>
                        <dt>숫자합</dt>
                        <dd className="sum"></dd>
                    </dl>
                    <GraphViewComponent/>
                    {
                    /*
                    <ReactHint
                               attribute="data-custom"
                               events={{hover: true}}
                               onRenderContent={this.onRenderContent}
                               ref={(ref) => this.instance = ref} />
                    */    
                    }
                    
                </div>
            </div>
        );
    }

    onRenderContent = (target, content) => {
        const {catId} = target.dataset;
        let botInfo = JSON.parse(catId);
        const width = 240;
        let avatarStyle = this.getAvatarStyle(botInfo.avatar);
        avatarStyle = {...avatarStyle, "zoom": "0.6", border:'0px'};
        return <div className="custom-hint__content" id="pickinfo">
            <h2>
                <span className="nickBox">
                    <div className="avatar" style={avatarStyle}>
                    </div>
                    &nbsp;&nbsp;
                    <span className="nick" >{botInfo.nickname}</span>
                </span>
            </h2>
            <ul>
                <li>
                    {
                        /*
                        <span className="col">구분</span><span className="etype">{botInfo.result.endsWith('_p')?'파워볼':'일반볼'}</span>
                        */
                    }
                </li>
                {
                    botInfo.recentWLcounter >= 0 &&
                    <li><span className="col">연승</span><span className={"emainrow red"}>▲ {Math.abs(botInfo.recentWLcounter)}연승</span></li>
                }
                {
                    botInfo.recentWLcounter < 0 &&
                    <li><span className="col">연승</span><span className={"emainrow blue"}>▼ {Math.abs(botInfo.recentWLcounter)}연패</span></li>
                }
                <li><span className="col">구분</span><span className="ewin red">{botInfo['winCount']}승</span><span
                    className="ewin"> / </span><span className="ewin blue">{botInfo['lostCount']}패</span> <span
                    className="ewin">({Math.floor(100 * botInfo['winCount']/(botInfo['winCount'] + botInfo['lostCount']))}%)</span></li>
            </ul>
        </div>
    };

    getAvatarStyle(avatar){
    }

    renderPlayerList(){
        const {sockets} = this.props;
        let {recentResult} = sockets;
        const {isBeingAnimation, isBeingAnimationForPickster} = this.state;
        const isDisplay  =!isBeingAnimation && isBeingAnimationForPickster;
        let lastItem = recentResult[recentResult.length - 1]
        let {playerList} = sockets;
        playerList.sort(function (a, b) {
            let wstreakDiff = a.wonStreak - b.wonStreak;
            let wcountDiff = a.winCount - b.winCount;
            let lcountDiff = b.lostCount - a.lostCount;
            return wstreakDiff * 1000000 + wcountDiff * 100 + lcountDiff;
        });
        if(isBeingAnimationForPickster){
            playerList = picksterRows;
        }
        if(isBeingAnimation){
            lastItem = recentResult[recentResult.length - 2]
        }
        picksterRows = playerList;

        let rows = [];
        for(let i=0; i < playerList.length; i++){
            let item = playerList[playerList.length - i - 1];
            if(isDisplay && parseInt(item.round) === parseInt(lastItem.pick_num2) && item.isHandled !== true ){
                let wonCount  = 0;
                let lostCount = 0;
                item.result.forEach(function(item){
                    let resCode = RESULTS[item];
                    let name  = resCode.split('-')[0];
                    let value = resCode.split('-')[1];
                    if(lastItem[name] === value){
                        wonCount ++;
                    }
                    else{
                        lostCount ++;
                    }
                });
                if(lostCount === 0){
                    item.wonStreak ++;
                    item.winCount ++;
                }else{
                    item.wonStreak = 0;
                    item.lostCount++;
                }
                item.isHandled = true;
            }
            rows.push(
                <div className={"po_box_left_new "} data-custom data-custom-at="top" data-cat-id={JSON.stringify(item)}>
                    <div className={'number'}>{item.wonStreak}</div>
                    <div className={"avatar avatar"+ item.avatar} style={{'backgroundSize':'cover'}}/>
                    <div className={'last_result'} style={{'display': (isDisplay ? "block": "none")}}>
                        <div className={'ltop poe-' + lastItem.poe}></div>
                        <div className={'rtop oe-' + lastItem.oe}></div>
                        <div className={'rbottom ou-' + lastItem.ou}></div>
                        <div className={'lbottom pou-' + lastItem.pou}></div>
                        <div className={lastItem.wsize}></div>
                    </div>
                    <div className={'new_pick ' + ((isDisplay && item.wonStreak === 0 && item.result.length > 0)?'new_pick_grey': '')}>
                        {
                            item.result.map(function(i){
                                let resCode = RESULTS[i];
                                let clName = 'rtop';
                                if(resCode.startsWith("poe-")) clName = 'ltop';
                                else if(resCode.startsWith("oe-")) clName = 'rtop';
                                else if(resCode.startsWith("ou-")) clName = 'rbottom';
                                else if(resCode.startsWith("pou-")) clName = 'lbottom';
                                return <div key={"new_pick_" + i} className={clName + " " + resCode}></div>
                            })
                        }
                        {
                            item.result.length === 0 &&
                            <div key={"new_pick_pass"} className={'new_pick_pass'}></div>
                        }
                    </div>
                    <div className={'pickster_detail'}>
                        {item.wonStreak}연승<br/>
                        {item.winCount}승 {item.lostCount}패
                    </div>
                </div>
            );
        }
        return rows;
    }

    renderRecentResult(){
        const {sockets} = this.props;
        let {recentResult, currentWinResult} = sockets;
        let {isBeingAnimation} = this.state;
        let rows = [];
        for(let i=0; i < recentResult.length; i++){
            let item = recentResult[recentResult.length - i - 1];
            if(isBeingAnimation === true && currentWinResult.round === item.round) continue;
            let strBalls = '';
            item.result.forEach(function (ball) {
                strBalls += ball.toString();
            });
            rows.push(
                <div className="po_box_right_new">
                    {item['pick_num2']}({item['pick_num']})<br/>
                    <ul className='first_tab'>
                        <li className={item["poe"]  + '_p'}></li>
                        <li className={item["pou"] + '_p'}></li>
                        <li className={'vl'}></li>
                        <li className={item["oe"]}></li>
                        <li className={item["ou"]}></li>
                    </ul>
                </div>
            );
        }
        return rows;
    }

    renderRecentResultNumbers(){
        const {sockets} = this.props;
        let {recentResult, currentWinResult} = sockets;
        let {isBeingAnimation} = this.state;
        let rows = [];
        for(let i=0; i < recentResult.length; i++){
            let item = recentResult[recentResult.length - i - 1];
            if(isBeingAnimation === true && currentWinResult.round === item.round) continue;
             rows.push(
                <div className="po_box_right_new">
                    {item['pick_num2']}({item['pick_num']})<br/>
                    <ul>
                        <li className='ball'>{item.result[0]}</li>
                        <li className='ball'>{item.result[1]}</li>
                        <li className='ball'>{item.result[2]}</li>
                        <li className='ball'>{item.result[3]}</li>
                        <li className='ball'>{item.result[4]}</li>
                        <li className="power-ball">{item.result[5]}</li>
                    </ul>
                </div>
            );
        }
        return rows;
    }
}

function mapStateToProps(state) {
    const { modal, sockets, game } = state;
    return {
        modal,
        sockets,
        game
    };
}

const connectedHomePage = connect(mapStateToProps)(GamePage);
export { connectedHomePage as GamePage };

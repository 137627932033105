import { socketConstants, gameConstants } from '../_constants';
var moment = require('moment');

const initialState = {
  currentResult : {},
  todayData: {todayGang:0, todayDang:0},
  gradeData: {
    powerball: {b_oe: [50, 50], b_ou: [50, 50], b_sect: [33, 33, 34],p_oe: [50, 50], p_ou: [50, 50], rd: 0 },
    powerball5: {b_oe: [50, 50], b_ou: [50, 50], b_sect: [33, 33, 34],p_oe: [50, 50], p_ou: [50, 50], rd: 0}
  },
  stateData: {oddEvenList:[], redBlueList:[], imageList:[]},
  recentResult : [],
    playerList : [],
  hourDiff : 0,
    clockDiff : 0,
  isEnded : false,
  isLoaded: false,
  resultIndex : 0,
}

export const sockets = (state = initialState, action) => {
    switch (action.type) {
        case socketConstants.AI_BET:
          state.playerList.push(action.data);
          return {...state };
        case socketConstants.BOT_LIST:
            state.playerList = action.data;
            return {...state };
        case socketConstants.GAME_START:
          state.currentResult = action.data;
          state.currentResult.secondsLeft = action.data.secondsLeft * 1;
          state.isEnded = false;
          return {...state };
        case gameConstants.DOWN_LEFTTIME:
          state.currentResult.secondsLeft = state.currentResult.secondsLeft -1;
          //if(state.currentResult.secondsLeft < 0) state.currentResult.secondsLeft = 0;
          return {...state };
        case socketConstants.GAME_END:
          state.currentWinResult = action.data;
          window.init_scoreboard();
          state.recentResult.push(action.data);
          state.playerList = [];
          state.isEnded = true;
          return {...state };
        case socketConstants.RECENT_RESULT:
            state.recentResult = action.data.resultList;
            return {...state };
        case socketConstants.TODAY_DATA:
          state.todayData = action.data;
          return {...state };
        case socketConstants.GAME_GRADE:
          state.gradeData = action.data;
          try{
              state.currentResult.secondsLeft = action.data.secondsLeft* 1;
              state.clockDiff = action.data.clockDiff;
              state.hourDiff = new Date(action.data.serverTime).getTime() - new Date().getTime();
          }catch (e) {
          }
          return {...state };
        case socketConstants.STATISTICS:
          state.stateData = action.data;
          state.resultIndex = action.data.resultIndex;
          state.isLoaded = true;
          return {...state };
        default:
          return {...state}
    }
}
